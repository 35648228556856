import React from "react"
import { Link, withPrefix } from "gatsby"
import Layout from "@layout/layout"
import { pl, en } from "@content/pages/become-volunteer.yaml"
import HeaderSubpage from "@layout/header-subpage"
import SEO from "@layout/seo"

export default ({isEn}) => {
  const translation = isEn ? en : pl

  return (
    <Layout isEn={isEn}>
      <SEO
        isEn={isEn}
        title={translation.metaTitle}
        description={translation.metaDescription}
      />
      <HeaderSubpage isEn={isEn}/>
      <section className="section wrapper">
        <section
          className="news-text padding wow fadeInUp"
          data-wow-duration="1.5s"
          style={{visibility: "hidden"}}
        >
          <div className="row">
            <div className="large-8 large-centered medium-12 small-12 columns">
              <h1 className="text-center">Zostań wolontariuszem</h1>
            </div>
          </div>
        </section>
        <section
          className="news-text padding wow fadeInUp"
          data-wow-duration="1.5s"
          style={{visibility: "hidden"}}
        >
          <div className="row">
            <div className="large-8 large-centered medium-12 small-12 columns">
              <p>
                Rekrutację na wolontariuszy prowadzimy RAZ DO ROKU – najczęściej
                w okolicach października! Dlatego jeśli chcesz do nas dołączyć –
                śledź nasz fanpage, tam wiadomości pojawiają się najszybciej!
              </p>
              <p>
                <a href={withPrefix("images/become-volunteer/hero.jpg")}>
                  <img
                    className="aligncenter"
                    src={withPrefix("images/become-volunteer/hero.jpg")}
                    alt=""
                  />
                </a>
              </p>
              <p>
                <strong>Kto może zostać wolontariuszem?</strong>
                <br />
                Wolontariuszem może zostać każda chętna osoba, której bliski
                jest cel naszej Fundacji. Piszesz ciekawe teksty, lubisz Social
                Media czy myślisz po prostu o zdobyciu doświadczenia w
                działalności NGO – zapraszamy! Zastrzegamy jednak, że
                wolontariaty medyczne skierowane są głównie do studentów
                medycyny i młodych lekarzy (np. stażystów, lekarzy w trakcie
                specjalizacji).
              </p>
              <p>
                <strong>Na czym polega praca wolontariusza?</strong>
                <br />
                Będąc wolontariuszem spoza obszaru medycznego, możesz wesprzeć
                nas w zbiórkach funduszy, logistycznej organizacji wyjazdów,
                pisania tekstów, projektowania grafik, a także przeprowadzania
                innych imprez, które organizujemy w ciągu roku!
              </p>
              <p>
                <strong>Na czym polega praca wolontariusza medycznego?</strong>
                <br />
                Jako student kierunku lekarskiego masz szansę na wzięcie udziału
                w wyjeździe na Ukrainę, sprawdzenia swojej wiedzy podczas
                Białych Niedziel, wzięcia udziału w warsztatach np.
                ginekologiczno-położniczych, jak również na wzięcie udziału w
                wolontariacie w Tanzanii.
                <br />
                Zanim jednak wyjedziesz za granicę – chcemy zobaczyć, że zależy
                Tobie na naszych celach, tak samo jak nam!
              </p>
              <p>
                Wolontariusze medyczni zanim zostaną zakwalifikowani do
                konkretnego programu, muszą wykazać się chęcią wspierania
                Fundacji – organizowanie zbiórek pieniędzy, sprzętu medycznego,
                nawiązywanie kontaktu z potencjalnymi darczyńcami czy nawet
                osobami mogącymi nas wesprzeć w promocji!
              </p>
              <p>
                Po wyjazdach wolontariusze często są proszeni do podzielenia się
                swoim doświadczeniem, występując w roli prelegentów na
                spotkaniach z osobami zainteresowanymi tematyką medycyny
                tropikalnej, zdrowia i podróży.
              </p>
              <p>
                Jeśli jesteś osobą, która chciałaby się zaangażować w nasze
                projekty – daj nam znać!
              </p>
            </div>
          </div>
        </section>
      </section>
    </Layout>
  )
}
